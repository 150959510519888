// Variables
@import "../../../sass/variables";

.bottom-button-aria {
  .dialog-close-btn {
    position: unset!important;
    width: unset!important;
    margin-left: 2rem;
  }
}


/////////////////////////////////////////////////
/////////////// タブレット対応 ///////////////
@media (max-width: 1020px) and (min-width: 768px) {
  .bottom-button-aria {
  }
}

/////////////////////////////////////////////////
/////////////// スマートフォン対応 ///////////////
@media (max-width: 767px) {
  .bottom-button-aria {
    position: relative;
    margin-top: 10px!important;
    height: 100px;

    .dialog-close-btn {
      margin: 0;
      width: 100%!important;
      position: absolute!important;
      top: 60px!important;
      left: 0!important;
    }

    #item-detail-link {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
